import React from 'react';

const FederatedWarningBar = () => {
	const styles = {
		backgroundColor: 'orange',
		textAlign: 'center',
		padding: '0.6rem',
	};
	return (
		<section style={styles}>
			Federated Login is enabled.{' '}
			{window.appConfig?.ALLOW_FEDERATED_EDITING === 'true'
				? 'Changes may be overwritten'
				: 'All user management must be handled'}{' '}
			by your Identity Provider.
		</section>
	);
};

export default FederatedWarningBar;
