import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	autoComplete: {
		'& .MuiInput-underline:before': {
			borderColor: 'lightgray',
			borderBottomStyle: 'solid',
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: theme.palette.info.main,
		},
		'& .MuiInput-underline::after': {
			borderColor: theme.palette.info.main,
		},
		'& .MuiFormLabel-root': {
			fontWeight: '500',
			color: '#333 !important',
		},
		'& .MuiInputLabel-shrink': {
			transform: 'translate(0, -6px) scale(1)',
			letterSpacing: '-0.36px',
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
			paddingBottom: '.6rem',
		},
	},
}));

const RoleAutocomplete = props => {
	const classes = useStyles();
	const {options, label, roles, onRolesChanged, InputProps, ...rest} = props;

	return (
		<Autocomplete
			multiple
			options={options}
			value={roles}
			onChange={(e, values) => onRolesChanged(values)}
			freeSolo={true}
			disableClearable
			filterSelectedOptions={true}
			autoSelect={true}
			className={classes.autoComplete}
			renderInput={params => (
				<TextField
					{...params}
					InputLabelProps={{shrink: true}}
					variant="standard"
					label={label}
					placeholder={roles.length ? '' : 'No groups assigned'}
					{...InputProps}
				/>
			)}
			{...rest}
		/>
	);
};

RoleAutocomplete.propTypes = {
	options: PropTypes.array,
	roles: PropTypes.arrayOf(PropTypes.string),
	onRolesChanged: PropTypes.func.isRequired,
	InputProps: PropTypes.object,
	label: PropTypes.string,
};

RoleAutocomplete.defaultProps = {
	options: ['admin', 'user', 'apiuser', 'submitter', 'readonly'],
	roles: [],
	InputProps: {},
};

export {RoleAutocomplete};
export default RoleAutocomplete;
