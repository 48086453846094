/* eslint-disable camelcase */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormControl} from '@material-ui/core';
import RoleAutocomplete from './RoleAutocomplete';
import axios from 'axios';
import {Prompt} from 'react-router-dom';
import {useAuth} from 'oidc-react';
import {ProfileContext} from '../Profile';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RoleForm = props => {
	const {fetchUser} = React.useContext(ProfileContext);
	const [globalRoles, setGlobalRoles] = useState(props.globalRoles);
	const [lenderRoles, setLenderRoles] = useState(props.lenderRoles);
	const [absRoles, setAbsRoles] = useState(props.absRoles);
	const auth = useAuth();
	const resetForm = () => {
		setGlobalRoles(props.globalRoles);
		setLenderRoles(props.lenderRoles);
		setAbsRoles(props.absRoles);
	};
	const saveChanges = e => {
		e.preventDefault();
		axios({
			url: `api/users/${props.sub}`,
			method: 'patch',
			headers: {
				Authorization: `Bearer ${auth.userData?.access_token}`,
			},
			data: {
				abs_global_roles: globalRoles,
				abs_lender_roles: lenderRoles,
				abs_suite_roles: absRoles,
			},
		}).then(result => {
			fetchUser(props.sub);
			try {
				toast.info('User roles were updated.', {
					position: 'bottom-center',
					toastId: 'userRolesSaved',
					hideProgressBar: true,
					closeButton: false,
				});
			} catch (e) {
				console.error(e);
			}
			return result.data;
		});
	};

	const isFormDirty =
		absRoles.toString() !== props.absRoles.toString() ||
		globalRoles.toString() !== props.globalRoles.toString() ||
		lenderRoles.toString() !== props.lenderRoles.toString();

	return (
		<section style={{flex: 1}}>
			<Prompt when={isFormDirty} message="You have unsaved role changes, do you wish to leave this page?" />
			<h3
				style={{
					color: '#8295a5',
					fontWeight: 'normal',
					textTransform: 'uppercase',
					marginBottom: '1rem',
				}}
			>
				Belongs to User Groups:
			</h3>
			<FormControl fullWidth margin="normal">
				<RoleAutocomplete
					disabled={props.isDisabled}
					onRolesChanged={value => {
						// Prevent users from entering "user admin" since "admin" is now displayed as "User Admin"
						setGlobalRoles(
							value.reduce((acc, val) => {
								// user added some form of User Admin
								if (val.match(/^user\s?admin$/i)) {
									// admin already in their roles, ignore it
									if (value.includes('admin')) return acc;
									// else change to 'admin'
									return [...acc, 'admin'];
								}
								//it was some other role, just allow it.
								return [...acc, val];
							}, []),
						);
					}}
					roles={globalRoles}
					getOptionLabel={option => (option === 'admin' ? 'User Admin' : option)}
					label="Universal Admins"
				/>
			</FormControl>
			<FormControl fullWidth margin="normal">
				<RoleAutocomplete
					disabled={props.isDisabled}
					onRolesChanged={setAbsRoles}
					roles={absRoles}
					label="ABS Suite Plus"
				/>
			</FormControl>
			<FormControl fullWidth margin="normal">
				<RoleAutocomplete
					disabled={props.isDisabled}
					onRolesChanged={setLenderRoles}
					roles={lenderRoles}
					label="Lender"
				/>
			</FormControl>
			{!props.isDisabled && isFormDirty && (
				<div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '2rem'}}>
					<Button
						variant="text"
						color="primary"
						type="button"
						style={{marginRight: '1rem'}}
						onMouseUp={resetForm}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						disabled={!isFormDirty}
						onMouseUp={saveChanges}
						style={{borderRadius: 0}}
					>
						Save Roles
					</Button>
				</div>
			)}
		</section>
	);
};

RoleForm.propTypes = {
	lenderRoles: PropTypes.arrayOf(PropTypes.string),
	absRoles: PropTypes.arrayOf(PropTypes.string),
	globalRoles: PropTypes.arrayOf(PropTypes.string),
	isDisabled: PropTypes.bool,
	sub: PropTypes.string,
};

RoleForm.defaultProps = {
	isDisabled: false,
	lenderRoles: [],
	absRoles: [],
	globalRoles: [],
};

export default RoleForm;
