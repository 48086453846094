import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useAuth} from 'oidc-react';
import {UserTable} from './components/UserTable';
import {NewUserDialog} from './components/NewUserDialog';

const UserManagement = () => {
	const [users, setUsers] = useState([]);
	const auth = useAuth();
	const currentUserIsFederated =
		window.appConfig?.ALLOW_FEDERATED_EDITING !== 'true' &&
		(auth.userData.profile.sub.startsWith('saml.') || auth.userData.profile.isFederated);
	const currentUserIsAdmin = auth.userData.profile.abs_global_roles.includes('admin');

	useEffect(() => {
		const fetchUsers = async () => {
			const result = await axios({
				url: 'api/users',
				headers: {
					Authorization: `Bearer ${auth.userData?.access_token}`,
				},
			});
			setUsers(result.data);
		};

		if (!currentUserIsFederated && currentUserIsAdmin) {
			fetchUsers();
		}
	}, [currentUserIsFederated, currentUserIsAdmin, auth.userData?.access_token]);

	const handleUserModified = (userId, modifiedUser) => {
		if (userId && modifiedUser) {
			setUsers(
				users.reduce((acc, user) => {
					if (user.id === userId) {
						return [...acc, modifiedUser];
					}
					return [...acc, user];
				}, []),
			);
		}
		return users;
	};

	const handleUserDeleted = userId => {
		setUsers(users.filter(x => x.id !== userId));
		return users;
	};

	if (currentUserIsFederated) {
		return (
			<article style={{textAlign: 'center'}}>
				<h2>User information is unavailable.</h2>
				<p>
					Federated Identity Management is enabled. Users must be managed via your Identity Provider.
					<br />
					<br />
					Please contact your system administrator for more information.
				</p>
			</article>
		);
	}

	if (!currentUserIsAdmin) {
		return <h4>You do not have access to view this page. Please contact an administrator to request access.</h4>;
	}

	return (
		<React.Fragment>
			<header style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
				<h4>Account Management</h4>
				<NewUserDialog />
			</header>
			<UserTable users={users} handleUserModified={handleUserModified} handleUserDeleted={handleUserDeleted} />
		</React.Fragment>
	);
};
export {UserManagement};

export default UserManagement;
