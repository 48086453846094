import {css} from 'styled-components';
import * as variables from './variables.styles';

const buttonsSharedStyle = css`
	width: 84px;
	height: 36px;
	margin: 0 4px 0 8px;
	border: none;
	padding: 10px 16px;
	text-transform: uppercase;
	border-radius: 0;
	&:disabled {
		opacity: 0.4;
	}
`;

export const PickerCancelButton = `
.MuiPickersModal-dialogRoot .MuiDialogActions-root .MuiButton-root:first-child {
  ${buttonsSharedStyle}
  background-color: ${variables.colors.white};
  color: ${variables.colors.greyNoHover};
  &:hover {
    background-color: ${variables.colors.blueLightOpacity};
    color: ${variables.colors.blue};
    cursor: pointer;
  }
}
`;

export const PickerOkButton = `
.MuiPickersModal-dialogRoot .MuiDialogActions-root .MuiButton-root:last-child {
  ${buttonsSharedStyle}
  background-color:${variables.colors.blue};
  color: ${variables.colors.white};
  &:hover {
    background-color: ${variables.colors.orange};
    cursor: pointer;
  }
}
`;
