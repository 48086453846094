/* eslint-disable camelcase */
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from '@material-ui/core';
import {PersonAdd} from '@material-ui/icons';
import axios from 'axios';
import {AbsInput} from '@moodysanalytics/cs-structured-ux-common';
import {useAuth} from 'oidc-react';

const useStyles = makeStyles(theme => ({
	dialogContent: {
		minWidth: '40rem',
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '40ch',
		},
	},
	requiredFieldMarker: {
		position: 'absolute',
		right: '2rem',
		top: '2rem',
		fontSize: '.8rem',
	},
}));

const NewUserDialog = () => {
	const classes = useStyles();
	const auth = useAuth();
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [submissionError, setSubmissionError] = useState();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleSave = () => {
		if (!displayName) {
			setDisplayName(`${firstName} ${lastName}`);
		}
		return axios({
			method: 'POST',
			url: 'api/users',
			headers: {
				Authorization: `Bearer ${auth.userData?.access_token}`,
			},
			data: {
				email: email,
				given_name: firstName,
				family_name: lastName,
				name: displayName,
				password: password,
			},
		})
			.then(result => {
				history.push(`/users/${result.data.id}`);
			})
			.catch(err => {
				setSubmissionError(err?.response?.data?.message || 'Error occurred creating new user.');
			});
	};

	const formIsInvalid = !email || !firstName || !lastName || !password || password !== confirmPassword;

	return (
		<React.Fragment>
			<IconButton color="primary">
				<PersonAdd onMouseUp={handleOpen} />
			</IconButton>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle style={{position: 'relative'}}>
					Add a new user
					<div className={classes.requiredFieldMarker}>* denotes a required field</div>
				</DialogTitle>
				<DialogContent className={classes.dialogContent} style={{display: 'flex', flexFlow: 'column nowrap'}}>
					<AbsInput
						margin="dense"
						value={email}
						onChange={e => setEmail(e.target.value)}
						id="email"
						label="Email"
						fullWidth
						required
					/>
					<AbsInput
						margin="dense"
						value={firstName}
						onChange={e => setFirstName(e.target.value)}
						id="firstName"
						label="First Name"
						fullWidth
						required
					/>
					<AbsInput
						margin="dense"
						value={lastName}
						onChange={e => setLastName(e.target.value)}
						id="lastName"
						label="Last Name"
						fullWidth
						required
					/>
					<AbsInput
						margin="dense"
						value={displayName}
						placeholder={`${firstName} ${lastName}`}
						onChange={e => setDisplayName(e.target.value)}
						id="displayName"
						label="Display Name (Optional)"
						fullWidth
					/>
					<AbsInput
						margin="dense"
						value={password}
						onChange={e => setPassword(e.target.value)}
						id="password"
						type="password"
						label="Password"
						fullWidth
						required
					/>
					<AbsInput
						margin="dense"
						error={password !== confirmPassword}
						helperText={password !== confirmPassword ? 'Passwords do not match' : undefined}
						value={confirmPassword}
						onChange={e => setConfirmPassword(e.target.value)}
						id="confirmPassword"
						name="confirmPassword"
						label="Confirm Password"
						type="password"
						fullWidth
						required
					/>
				</DialogContent>
				{submissionError && (
					<div
						style={{
							whiteSpace: 'pre',
							padding: '1rem',
							color: 'red',
							textAlign: 'center',
						}}
					>
						{submissionError}
					</div>
				)}
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button disabled={formIsInvalid} onClick={handleSave} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export {NewUserDialog};
