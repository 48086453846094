import axios from 'axios';

const fetchUser = async (userId, accessToken) => {
	return axios({
		url: `api/users/${userId}`,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	}).then(response => {
		return response.data;
	});
};

export {fetchUser};
