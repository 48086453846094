import React from 'react';
import {Header, Footer} from '@moodysanalytics/cs-structured-ux-common';
import {Route, Redirect, Switch} from 'react-router-dom';
import {useAuth} from 'oidc-react';
import ProfileContainer from '../Profile/Profile';
import {UserManagement} from '../UserManagement';
import FederatedWarningBar from './components/FederatedWarningBar';
import {Container, Box, ThemeProvider} from '@material-ui/core';
import {ErrorBoundary} from '../../components/ErrorBoundary';
import {GlobalTheme} from '../../styles/global.styles';
import {ToastContainer} from 'react-toastify';

const links = [
	{name: 'My Profile', link: '/profile', exact: false},
	{name: 'Account Management', link: '/users', exact: false},
];

export const Home = () => {
	const auth = useAuth();
	if (auth.isLoading || !auth.userData || auth.userData.expired) return null;
	const currentUserIsFederated = Boolean(
		auth.userData?.profile?.sub?.startsWith?.('saml.') || auth.userData?.profile?.isFederated,
	);
	return (
		<ThemeProvider theme={GlobalTheme}>
			<Box display="flex" flexDirection="column" height="100vh" width="100vw">
				<ErrorBoundary>
					<Header
						buildNumber={window.appConfig?.BUILD_VERSION || '2.1.0'}
						walkMeSrc={window.appConfig?.WALKME_SCRIPT_URL}
						links={links}
						userProfile={auth.userData.profile}
						isAdmin={Boolean(auth.userData.profile?.abs_global_roles?.includes?.('admin'))}
						issuerUrl={auth.userManager.settings.authority}
						beforeLogout={async e => {
							e?.preventDefault?.();
							await auth.userManager.signoutRedirect();
							return Promise.reject();
						}}
						identityUrl={window.appConfig?.IDENTITY_URL}
						lenderUrl={window.appConfig?.LENDER_URL}
						capitalMarketsUrl={window.appConfig?.CAPITAL_MARKETS_URL}
						administrationUrl={window.appConfig?.ADMINISTRATION_URL}
						availableApps={window.appConfig?.AVAILABLE_APPS}
						disabledApps={[
							!(window.appConfig?.FUNDING_APPROVALS_ENABLED === 'true' || window.appConfig?.LEDGER_APPROVALS_ENABLED === 'true') ? 'approvals' : '',
						]}
						absApp="identity"
						theme={GlobalTheme}
					/>
				</ErrorBoundary>
				<ErrorBoundary>
					{currentUserIsFederated && <FederatedWarningBar />}
					<Container
						style={{
							height: '100%',
							flex: '1 1 100%',
							overflow: 'hidden',
							display: 'flex',
							flexFlow: 'column nowrap',
						}}
					>
						<Switch>
							<Route path="/profile" component={ProfileContainer} />
							<Route path="/users/:userId" component={ProfileContainer} />
							<Route exact path="/users" component={UserManagement} />
							<Redirect exact from="/" to="/profile" />
							<Route path="*" render={() => <div>Unknown Route</div>} />
						</Switch>
					</Container>
				</ErrorBoundary>
				<Footer buildNumber={window.appConfig?.BUILD_VERSION || '2.1.0'} />
			</Box>
			<ToastContainer />
		</ThemeProvider>
	);
};

export default Home;
