import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {useAuth} from 'oidc-react';
import axios from 'axios';
import {ProfileContext} from '../Profile';

const ActivateUserDialog = props => {
	const {fetchUser} = React.useContext(ProfileContext);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [open, setOpen] = props.toggleVisibility ? [props.open, props.toggleVisibility] : useState(false);
	const [submissionError, setSubmissionError] = useState();
	const auth = useAuth();
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		axios({
			headers: {
				Authorization: `Bearer ${auth.userData.access_token}`,
			},
			url: `api/users/${props.sub}/${props.userIsDisabled ? 'activate' : 'deactivate'}`,
			method: 'POST',
		})
			.then(response => {
				if (props.handleUserSaved) {
					props.handleUserSaved(response.data);
				} else {
					fetchUser();
				}
				handleClose();
			})
			.catch(err => {
				setSubmissionError(err?.response?.data?.message || 'Failed to change user status.');
			});
	};
	return (
		<React.Fragment>
			{!props.hideButton && (
				<div onClick={handleClickOpen}>{props.userIsDisabled ? 'Activate' : 'Deactivate'}</div>
			)}
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					{props.userIsDisabled ? 'Activate' : 'Deactivate'} User
				</DialogTitle>
				<DialogContent style={{display: 'flex', flexFlow: 'column nowrap'}}>
					<DialogContentText>
						Do you want to {props.userIsDisabled ? 'activate' : 'deactivate'} {props.name || 'this user'}?
					</DialogContentText>
				</DialogContent>
				{submissionError && <div style={{color: 'red', textAlign: 'center'}}>{submissionError}</div>}
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSave} variant="contained" color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

ActivateUserDialog.propTypes = {
	userIsDisabled: PropTypes.bool.isRequired,
	sub: PropTypes.string.isRequired,
	name: PropTypes.string,
	handleUserSaved: PropTypes.func,
	hideButton: PropTypes.bool,
	toggleVisibility: PropTypes.func,
	open: PropTypes.bool,
};

ActivateUserDialog.defaultProps = {
	hideButton: false,
	open: false,
};

export {ActivateUserDialog};
