import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {useAuth} from 'oidc-react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';

const DeleteUserDialog = props => {
	const history = useHistory();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [open, setOpen] = props.toggleVisibility ? [props.open, props.toggleVisibility] : useState(false);
	const [submissionError, setSubmissionError] = useState();
	const auth = useAuth();
	if (auth.userData.profile.sub === props.sub) {
		if (props.hideButton) return null;
		return <div aria-disabled={true}>Delete</div>;
	}
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		axios({
			headers: {
				Authorization: `Bearer ${auth.userData.access_token}`,
			},
			url: `api/users/${props.sub}`,
			method: 'DELETE',
		})
			.then(() => {
				if (props.onUserDeleted) {
					props.onUserDeleted(props.sub);
					handleClose();
				} else {
					history.push('/users');
				}
			})
			.catch(err => {
				setSubmissionError(err?.response?.data?.message || 'Unable to delete user.');
			});
	};
	return (
		<React.Fragment>
			{!Boolean(props.hideButton) && <div onClick={handleClickOpen}>Delete</div>}
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Delete User</DialogTitle>
				<DialogContent style={{display: 'flex', flexFlow: 'column nowrap'}}>
					<DialogContentText>Are you sure you wish to delete {props.name || 'this user'}?</DialogContentText>
				</DialogContent>
				{submissionError && <div style={{color: 'red', textAlign: 'center'}}>{submissionError}</div>}
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSave} variant="contained" color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

DeleteUserDialog.propTypes = {
	hideButton: PropTypes.bool,
	onUserDeleted: PropTypes.func,
	toggleVisibility: PropTypes.func,
	open: PropTypes.bool,
	sub: PropTypes.string.isRequired,
	name: PropTypes.string,
};

DeleteUserDialog.defaultProps = {
	hideButton: false,
	open: false,
};

export {DeleteUserDialog};
