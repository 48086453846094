import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {AbsInput} from '@moodysanalytics/cs-structured-ux-common';
import {useAuth} from 'oidc-react';
import axios from 'axios';

const ChangePasswordDialog = props => {
	const [open, setOpen] = React.useState(false);
	const [currentPassword, setCurrentPassword] = React.useState('');
	const [newPassword, setNewPassword] = React.useState('');
	const [confirmNewPassword, setConfirmPassword] = React.useState('');
	const [submissionError, setSubmissionError] = React.useState('');
	const auth = useAuth();
	const handleValueChanged = (method, value) => {
		method(value);
		setSubmissionError('');
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const userIsAdmin = Boolean(auth?.userData?.profile?.abs_global_roles?.includes?.('admin'));
	const isCurrentPasswordRequired = !userIsAdmin || props.sub === auth?.userData?.profile?.sub;

	const isFormInvalid = () => {
		if (!newPassword.length) {
			return 'You must enter a new password.';
		}
		if (newPassword !== confirmNewPassword) {
			return 'Passwords do not match.';
		}
		return false;
	};

	const handleSave = e => {
		e?.preventDefault?.();
		e?.stopPropagation?.();
		axios({
			headers: {
				Authorization: `Bearer ${auth.userData.access_token}`,
			},
			url: `api/users/${props.sub}/password`,
			method: 'POST',
			data: {
				currentPassword,
				newPassword,
			},
		})
			.then(() => handleClose())
			.catch(err => {
				setSubmissionError(err?.response?.data?.message || 'Failed to update password.');
			});
	};

	return (
		<React.Fragment>
			<div onClick={handleClickOpen}>Change Password</div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<form onSubmit={handleSave} style={{minWidth: '50rem'}}>
					<DialogTitle id="form-dialog-title">Change Password</DialogTitle>
					<DialogContent style={{display: 'flex', flexFlow: 'column nowrap'}}>
						<DialogContentText>Changes will take effect on next login.</DialogContentText>
						{isCurrentPasswordRequired && (
							<AbsInput
								autoFocus
								margin="dense"
								value={currentPassword}
								onChange={e => handleValueChanged(setCurrentPassword, e.target.value)}
								id="currentPassword"
								label="Old Password"
								type="password"
								name="currentPassword"
								fullWidth
								required
							/>
						)}
						<AbsInput
							margin="dense"
							value={newPassword}
							onChange={e => handleValueChanged(setNewPassword, e.target.value)}
							id="newPassword"
							label="New Password"
							type="password"
							name="newPassword"
							required
							fullWidth
						/>
						<AbsInput
							margin="dense"
							error={newPassword !== confirmNewPassword}
							helperText={newPassword !== confirmNewPassword ? 'Passwords do not match' : undefined}
							value={confirmNewPassword}
							onChange={e => handleValueChanged(setConfirmPassword, e.target.value)}
							id="confirmPassword"
							label="Confirm Password"
							type="password"
							required
							fullWidth
						/>
						{submissionError && (
							<div style={{padding: '1rem', whiteSpace: 'pre', color: 'red', textAlign: 'center'}}>
								{submissionError}
							</div>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} type="button" color="primary">
							Cancel
						</Button>
						<Button disabled={!!isFormInvalid()} type="submit" variant="contained" color="primary">
							Confirm
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};

ChangePasswordDialog.propTypes = {
	sub: PropTypes.string,
};

export {ChangePasswordDialog};
