/* eslint-disable camelcase */
import {UserManager, Log} from 'oidc-react';
if (window.appConfig?.OIDC_REACT_LOGGING === 'true') {
	Log.level = Log[window.appConfig?.OIDC_REACT_LOGGING_LEVEL || 'NONE'];
	Log.logger = console;
}

export const userManager = new UserManager({
	authority: `${document.baseURI}oidc`,
	client_id: process.env.CLIENT_ID || 'abs_plus_id',
	monitorSession: true,
	automaticSilentRenew: true,
	redirect_uri: document.baseURI.slice(0, -1),
	post_logout_redirect_uri: document.baseURI.slice(0, -1),
	response_type: 'code',
	scope: 'openid profile email roles offline_access',
});

export default {userManager};
