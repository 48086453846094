import {createGlobalStyle} from 'styled-components';
import {createMuiTheme} from '@material-ui/core';
// Variables
import {PickerCancelButton, PickerOkButton} from './overrides.styles';

export const GlobalStyle = createGlobalStyle`
  html {
	font-size: 62.5%;
  }
  ${PickerCancelButton}
  ${PickerOkButton}
`;

export const GlobalTheme = createMuiTheme({
	palette: {
		common: {
			black: '#333333',
		},
		background: {
			paper: '#FFF',
			default: '#FAFAFA',
		},
		primary: {
			main: '#135E82',
		},
		secondary: {
			main: '#e98c05',
		},
		error: {
			main: '#b82121',
		},
		info: {
			main: '#4084f8',
		},
		warning: {
			main: '#e98c05',
		},
		success: {
			main: '#69a74b',
		},
		text: {
			primary: '#333333',
		},
	},
	typography: {
		htmlFontSize: 10,
		fontSize: 14,
	},
	overrides: {
		MuiAutocomplete: {
			tag: {
				backgroundColor: 'rgba(129, 149, 165, 0.2)',
			},
		},
	},
});
