/* eslint-disable camelcase */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	makeStyles,
	Button,
	Chip,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Person, NotInterested, People, Block, Check, Delete} from '@material-ui/icons';
import {ActivateUserDialog} from '../../Profile/components/ActivateUserDialog';
import {DeleteUserDialog} from '../../Profile/components/DeleteUserDialog';
import ABSIconBlue from '../../../assets/images/favicon-abssuite-101-transparent-back.png';
import ReporterIconBlue from '../../../assets/images/favicon-reporter-101-transparent-back.png';
import LenderIconBlue from '../../../assets/images/favicon-lender-101-transparent-back.png';

const useStyles = makeStyles(theme => ({
	table: {
		minWidth: 650,
	},
	tableHeader: {
		border: '1px solid #dbdbdb',
		backgroundColor: 'rgb(239, 239, 239)',
	},
	roleChip: {
		margin: '.6rem',
		backgroundColor: 'rgba(129, 149, 165, 0.2)',
	},
	deleteUserButton: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.light,
		},
	},
}));

const MemberOfCellContent = props => {
	const {user} = props;
	const classes = useStyles();
	return (
		<React.Fragment>
			{Boolean(user.abs_global_roles?.length) && (
				<Tooltip title={user.abs_global_roles.join(', ')} arrow>
					<Chip icon={<People />} label={'Universal Admins'} className={classes.roleChip} />
				</Tooltip>
			)}
			{Boolean(user.capital_markets_roles?.length) && (
				<Tooltip title={user.capital_markets_roles.join(', ')} arrow>
					<Chip
						icon={<img src={ABSIconBlue} alt="abs-icon" />}
						label={'Capital Markets'}
						className={classes.roleChip}
					/>
				</Tooltip>
			)}
			{Boolean(user.abs_suite_roles?.length) && (
				<Tooltip title={user.abs_suite_roles.join(', ')} arrow>
					<Chip
						icon={<img src={ABSIconBlue} alt="abs-icon" />}
						label={'Administration'}
						className={classes.roleChip}
					/>
				</Tooltip>
			)}
			{Boolean(user.abs_lender_roles?.length) && (
				<Tooltip title={user.abs_lender_roles.join(', ')} arrow>
					<Chip
						icon={<img src={LenderIconBlue} alt="lender-icon" />}
						label={'Lender'}
						className={classes.roleChip}
					/>
				</Tooltip>
			)}
			{Boolean(user.abs_reporter_roles?.length) && (
				<Tooltip title={user.abs_reporter_roles.join(', ')} arrow>
					<Chip
						icon={<img src={ReporterIconBlue} alt="reporter-icon" />}
						label={'Reporter'}
						className={classes.roleChip}
					/>
				</Tooltip>
			)}
		</React.Fragment>
	);
};
MemberOfCellContent.propTypes = {
	user: PropTypes.object,
};

const UserNameCell = props => {
	const {user} = props;
	return (
		<Link
			to={`/users/${user.id}`}
			style={{
				whiteSpace: 'no-wrap',
				display: 'flex',
				flexFlow: 'row nowrap',
				alignItems: 'center',
				textDecoration: 'none',
			}}
		>
			{Boolean(user.isDisabled) ? (
				<NotInterested color={'error'} />
			) : (
				<Person style={{color: '#69a74b'}} color={'inherit'} />
			)}
			<div style={{display: 'flex', flexFlow: 'column nowrap', margin: '0 1rem'}}>
				<div
					style={{
						color: '#333',
						fontSize: '1.6rem',
						letterSpacing: 0,
						lineHeight: 1,
						whiteSpace: 'nowrap',
						fontWeight: 'medium',
					}}
				>
					{`${user.given_name} ${user.family_name}`}
				</div>
				<small style={{fontSize: '1.2rem', color: 'gray', fontStyle: 'italic'}}>ID: {user.id}</small>
			</div>
		</Link>
	);
};

UserNameCell.propTypes = {
	user: PropTypes.object,
};
UserNameCell.defaultProps = {
	user: {},
};

const UserTable = props => {
	const classes = useStyles();
	const {users} = props;
	const [selectedUser, setSelectedUser] = useState();
	const [userDeletionDialogOpen, setUserDeletionDialogOpen] = useState(false);
	const [userStatusDialogOpen, setUserStatusDialogOpen] = useState(false);
	const handleStatusChangeClicked = user => {
		setSelectedUser(user);
		setUserStatusDialogOpen(true);
	};
	const handleDeleteClicked = user => {
		setSelectedUser(user);
		setUserDeletionDialogOpen(true);
	};
	return (
		<TableContainer component={Paper} style={{maxHeight: 'calc(100vh - 200px)'}}>
			<Table stickyHeader className={classes.table} aria-label="simple table" size="small">
				<TableHead className={classes.tableHeader}>
					<TableRow style={{backgroundColor: '#efefef'}}>
						<TableCell style={{fontWeight: 'bold', color: '#2c5f81', fontSize: '1.2rem'}}>
							User Name
						</TableCell>
						<TableCell style={{fontWeight: 'bold', color: '#2c5f81', fontSize: '1.2rem'}}>E-mail</TableCell>
						<TableCell style={{fontWeight: 'bold', color: '#2c5f81', fontSize: '1.2rem'}}>
							Member Of
						</TableCell>
						<TableCell style={{fontWeight: 'bold', color: '#2c5f81', fontSize: '1.2rem'}}>
							Actions
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map(user => (
						<TableRow key={user._id}>
							<TableCell component="th" scope="row">
								<UserNameCell user={user} />
							</TableCell>
							<TableCell>{user.email}</TableCell>
							<TableCell>
								<MemberOfCellContent user={user} />
							</TableCell>
							<TableCell style={{whiteSpace: 'nowrap'}}>
								<IconButton color="primary" onMouseUp={() => handleStatusChangeClicked(user)}>
									{user.isDisabled ? <Check /> : <NotInterested />}
								</IconButton>
								<IconButton color="primary" onMouseUp={() => handleDeleteClicked(user)}>
									<Delete />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{!!userStatusDialogOpen && (
				<ActivateUserDialog
					hideButton={true}
					open={userStatusDialogOpen}
					toggleVisibility={setUserStatusDialogOpen}
					handleUserSaved={newUser => props.handleUserModified?.(selectedUser?.id, newUser)}
					name={selectedUser?.name}
					userIsDisabled={Boolean(selectedUser?.isDisabled)}
					sub={selectedUser?.id}
				/>
			)}
			{!!userDeletionDialogOpen && (
				<DeleteUserDialog
					hideButton={true}
					open={userDeletionDialogOpen}
					toggleVisibility={setUserDeletionDialogOpen}
					onUserDeleted={() => props.handleUserDeleted?.(selectedUser?.id)}
					sub={selectedUser?.id}
					name={selectedUser?.name}
				/>
			)}
		</TableContainer>
	);
};

UserTable.propTypes = {
	users: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			id: PropTypes.string,
			preferred_username: PropTypes.string,
			email: PropTypes.string,
			given_name: PropTypes.string,
			family_name: PropTypes.string,
		}),
	),
	handleUserModified: PropTypes.func,
	handleUserDeleted: PropTypes.func,
};

UserTable.defaultProps = {
	users: [],
};

export {UserTable};
