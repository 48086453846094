import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AbsInput} from '@moodysanalytics/cs-structured-ux-common';
import {Box, Button, IconButton} from '@material-ui/core';
import {ChangePasswordDialog} from './ChangePasswordDialog';
import {useAuth} from 'oidc-react';
import {Link, Prompt, Route} from 'react-router-dom';
import axios from 'axios';
import {BlockOutlined, CheckOutlined, KeyboardBackspace} from '@material-ui/icons';
import {colors} from '../../../styles/variables.styles';
import {ActivateUserDialog} from './ActivateUserDialog';
import {DeleteUserDialog} from './DeleteUserDialog';
import {ProfileContext} from '../Profile';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserForm = props => {
	const {fetchUser} = React.useContext(ProfileContext);
	const [firstName, setFirstName] = useState(props.firstName);
	const [lastName, setLastName] = useState(props.lastName);
	const [email, setEmail] = useState(props.email);
	const [displayName, setDisplayName] = useState(props.displayName);
	const {userData} = useAuth();
	const saveChanges = e => {
		e.preventDefault();
		axios({
			url: `api/users/${props.sub}`,
			method: 'patch',
			headers: {
				Authorization: `Bearer ${userData?.access_token}`,
			},
			data: {
				//eslint-disable-next-line
				family_name: lastName,
				//eslint-disable-next-line
				given_name: firstName,
				name: displayName,
				email,
			},
		}).then(result => {
			fetchUser(props.sub);
			try {
				toast.info('User details were updated.', {
					position: 'bottom-center',
					toastId: 'userInfoSaved',
					hideProgressBar: true,
					closeButton: false,
				});
			} catch (e) {
				console.error(e);
			}
			return result.data;
		});
	};
	const resetForm = () => {
		setFirstName(props.firstName);
		setLastName(props.lastName);
		setEmail(props.email);
		setDisplayName(props.displayName);
	};

	const isFormDirty =
		firstName !== props.firstName ||
		lastName !== props.lastName ||
		email !== props.email ||
		displayName !== props.displayName;

	const isCurrentUser = () => props.sub === userData?.profile.sub;
	return (
		<form
			onSubmit={saveChanges}
			noValidate
			autoComplete="off"
			style={{flex: '0 1 32rem', marginRight: '8rem', position: 'relative'}}
		>
			<Prompt when={isFormDirty} message={() => `You have unsaved changes, do you wish to leave this page?`} />
			<Box display="flex" alignItems="center" m="3">
				<Route
					path="/users/:userId"
					render={() => {
						return (
							<Link to="/users" style={{position: 'absolute', left: '-4.5rem'}}>
								<IconButton>
									<KeyboardBackspace />
								</IconButton>
							</Link>
						);
					}}
				/>

				{props.isDisabled ? (
					<BlockOutlined color="error" />
				) : (
					<CheckOutlined style={{color: colors.greenSuccess}} />
				)}
				<div
					style={{
						fontSize: '1.8rem',
						fontWeight: '500',
						marginTop: '1rem',
						marginBottom: '.4rem',
					}}
				>
					{props.firstName} {props.lastName}
				</div>
			</Box>
			{props.isReadOnly && <div style={{marginTop: '.5rem', marginBottom: '1rem'}} />}
			{!props.isReadOnly && (
				<div style={{display: 'flex', flexFlow: 'row nowrap', marginTop: '.5rem', marginBottom: '1rem'}}>
					<div
						style={{
							cursor: 'pointer',
							textDecoration: 'none',
							color: '#135e82',
							fontSize: '10px',
							marginRight: '1rem',
						}}
					>
						<ActivateUserDialog
							sub={props.sub}
							name={`${props.firstName} ${props.lastName}`}
							userIsDisabled={Boolean(props.isDisabled)}
							hideButton={Boolean(props.isReadOnly)}
						/>
					</div>
					<div
						style={{
							cursor: isCurrentUser() ? 'default' : 'pointer',
							textDecoration: 'none',
							color: isCurrentUser() ? '#AAAAAA' : '#135e82',
							fontSize: '10px',
							marginRight: '1rem',
							visibility: props.isReadOnly ? 'none' : 'unset',
						}}
					>
						<DeleteUserDialog sub={props.sub} name={`${props.firstName} ${props.lastName}`} />
					</div>
					{!props.isReadOnly && (
						<div
							style={{
								cursor: 'pointer',
								textDecoration: 'none',
								color: '#135e82',
								fontSize: '10px',
								marginRight: '1rem',
							}}
						>
							<ChangePasswordDialog sub={props.sub} />
						</div>
					)}
				</div>
			)}
			<div style={{display: 'flex', flexFlow: 'column nowrap'}}>
				<AbsInput
					label="User ID"
					value={props.sub}
					name="userId"
					errors={{}}
					InputProps={{
						readOnly: true,
					}}
				/>
				<AbsInput
					label="First Name"
					value={firstName}
					onChange={e => setFirstName(e.target.value)}
					name="firstName"
					errors={{}}
					InputProps={{
						readOnly: props.isReadOnly,
					}}
				/>
				<AbsInput
					label="Last Name"
					value={lastName}
					onChange={e => setLastName(e.target.value)}
					name="lastName"
					errors={{}}
					InputProps={{
						readOnly: props.isReadOnly,
					}}
				/>
				<AbsInput
					label="Display Name"
					value={displayName}
					onChange={e => setDisplayName(e.target.value)}
					name="displayName"
					errors={{}}
					InputProps={{
						readOnly: props.isReadOnly,
					}}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<AbsInput
					label="Email"
					value={email}
					onChange={e => setEmail(e.target.value)}
					name="email"
					errors={{}}
					InputProps={{
						readOnly: props.isReadOnly,
					}}
				/>
			</div>
			{!props.isReadOnly && isFormDirty && (
				<div style={{display: 'flex', justifyContent: 'space-between', marginTop: '2rem'}}>
					<Button variant="text" color="primary" type="button" onMouseDown={resetForm}>
						Cancel
					</Button>
					<Button
						disabled={!isFormDirty}
						variant="contained"
						color="primary"
						type="submit"
						style={{borderRadius: 0}}
					>
						Save Profile
					</Button>
				</div>
			)}
		</form>
	);
};

UserForm.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	displayName: PropTypes.string,
	sub: PropTypes.string,
	userId: PropTypes.string,
	isDisabled: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
	firstName: '',
	lastName: '',
	email: '',
	displayName: '',
	sub: '',
	userId: '',
	isDisabled: false,
	isReadOnly: false,
	onSubmit: () => null,
};

export default UserForm;
