import {hot} from 'react-hot-loader/root';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from 'oidc-react';
// Styles
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import {GlobalStyle, GlobalTheme} from './styles/global.styles';
import {Home} from './containers/Home/Home';
import {userManager} from './AuthUtil';

function App() {
	return (
		<AuthProvider userManager={userManager}>
			<ThemeProvider theme={GlobalTheme}>
				<BrowserRouter basename={new URL(document.baseURI).pathname}>
					<CssBaseline />
					<GlobalStyle />
					<Home />
				</BrowserRouter>
			</ThemeProvider>
		</AuthProvider>
	);
}

export default hot(App);
