export const colors = {
	blue: '#135e82',
	blueHover: '#0c5274',
	blueField: '#4084f8',
	blueLight: '#2c5f81',
	blueOpacity: 'rgba(64, 132, 248, 0.2)',
	blueLightOpacity: 'rgba(19, 94, 130, 0.1)',
	orange: '#e98c05',
	greenSuccess: '#69a74b',
	redError: '#b82121',
	grey: '#efefef',
	greyNoHover: '#8295a5',
	greyLight: '#dbdbdb',
	greyOpacity: '#ddd',
	white: '#ffffff',
	blackOpacity: 'rgba(0, 0, 0, 0.6)',
	errorOpacity: 'rgba(134, 0, 0, 0.85)',
	black: '#000000',
};
