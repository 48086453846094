import React, {useState, useEffect} from 'react';
import {useAuth} from 'oidc-react';
import {useLocation, useParams} from 'react-router-dom';
import UserForm from './components/UserForm';
import RoleForm from './components/RoleForm';
import {fetchUser} from '../../api/users';

const ProfileContext = React.createContext({
	user: undefined,
	fetchUser: async () => {},
});

const ProfileContainer = () => {
	const auth = useAuth();
	const params = useParams();
	const location = useLocation();
	const [user, setUser] = useState();
	const [error, setError] = useState();
	const isViewingSelf = ['/', '/profile'].includes(location.pathname) || auth.userData.profile.sub === params.userId;
	const currentUserIsAdmin = Boolean(auth.userData?.profile?.abs_global_roles?.includes?.('admin'));
	const currentUserIsFederated =
		window.appConfig?.ALLOW_FEDERATED_EDITING !== 'true' &&
		(auth.userData?.profile?.sub.startsWith('saml.') || auth.userData?.profile?.isFederated);

	useEffect(() => {
		function loadUserInfo(userId) {
			setUser(null);
			fetchUser(userId, auth.userData?.access_token)
				.catch(err => {
					console.log(err);
					setError('User not found');
				})
				.then(user => {
					setUser(user);
					setError(null);
					return user;
				});
		}
		if (isViewingSelf) {
			loadUserInfo(auth.userData.profile.sub);
		} else if (!currentUserIsFederated && currentUserIsAdmin) {
			loadUserInfo(params.userId);
		}
	}, [
		isViewingSelf,
		currentUserIsFederated,
		currentUserIsAdmin,
		params.userId,
		location.pathname,
		auth.userData?.profile.sub,
		auth.userData?.access_token,
	]);

	if (!isViewingSelf && !currentUserIsAdmin) {
		return (
			<div>
				<h4>You are not authorized to edit other users.</h4>
				<p>Please request the appropriate role from a global administrator.</p>
			</div>
		);
	}
	if (error) {
		return (
			<div>
				<h4>{error}</h4>
			</div>
		);
	}
	if (!user) return null;
	return (
		<ProfileContext.Provider
			value={{
				user,
				fetchUser: async userId => {
					const user = await fetchUser(userId, auth.userData?.access_token);
					setUser(user);
				},
			}}
		>
			<article style={{display: 'flex', flexFlow: 'row nowrap', padding: '1rem 2rem'}}>
				<UserForm
					sub={user.id}
					userId={user.preferred_username}
					email={user.email}
					firstName={user.given_name}
					lastName={user.family_name}
					displayName={user.name}
					isDisabled={Boolean(user.isDisabled)}
					isReadOnly={currentUserIsFederated}
				/>
				<RoleForm
					isDisabled={currentUserIsFederated || !currentUserIsAdmin}
					sub={user.id}
					globalRoles={user.abs_global_roles}
					absRoles={user.abs_suite_roles}
					lenderRoles={user.abs_lender_roles}
					reporterRoles={user.abs_reporter_roles}
				/>
			</article>
		</ProfileContext.Provider>
	);
};

export {ProfileContainer, ProfileContext};
export default ProfileContainer;
